<template>
  <v-container
    fluid
    class="content pa-0"
  >
    <v-row
      v-if="admin"
      :class="{
        'mx-16': $vuetify.breakpoint.mdAndUp,
        'mx-6': $vuetify.breakpoint.smAndDown,
        'my-8': $vuetify.breakpoint.mdAndUp,
        'my-2': $vuetify.breakpoint.smAndDown
      }"
    >
      <v-spacer />
      <v-col
        cols="12"
      >
        <v-card
          class="pa-5"
          elevation="6"
        >
          <tinyMCE
            v-model="home_header"
            :init="{
              content_css: false,
              skin: false,
              setup: function (ed) {
                ed.on('init', function () {
                  this.getDoc().body.style.fontSize = '12';
                  this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                });
              },
              statusbar: false,
              height: 200,
              menubar: false,
              paste_as_text: true,
              language_url: '/langs/de.js',
              language: 'de',
              block_formats: 'Text=p;',
              toolbar:
                'undo redo | bold italic underline '
            }"
          />
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row
      v-if="!admin && carouselTextItems.length"
      no-gutters
    >
      <div class="carouselHolder">
        <VueSlickCarousel
          :arrows="false"
          :fade="true"
          :infinite="true"
          :speed="3000"
          :autoplay-speed="5000"
          :autoplay="true"
        >
          <div
            v-for="item in carouselTextItems"
            :key="item"
            :class="{
              'carouselDiv': true,
              'smallCarousel': $vuetify.breakpoint.mdAndDown,
              'largeCarousel': $vuetify.breakpoint.lgOnly,
              'xlCarousel': $vuetify.breakpoint.xlOnly
            }"
          >
            {{ item }}
          </div>

          />
        </VueSlickCarousel>
      </div>
    </v-row>
    <v-row
      :class="{
        'mx-16': $vuetify.breakpoint.mdAndUp,
        'mx-6': $vuetify.breakpoint.smAndDown
      }"
      justify="space-between"
    >
      <v-col
        lg="3"
        sm="6"
        cols="12"
        class="mx-0"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            class="transparentBack"
            :elevation="hover ? 10 : 6"
            @click="clickHandler('leerstand', $event)"
          >
            <v-img
              class="fotoHolder"
              width="100%"
              height="140"
              src="/img/startseite-leerstand.jpeg"
              position="bottom center"
            />
            <v-sheet
              class="pa-4 backgroundGradient"
              :class="{'sameHeight' : !admin}"
            >
              <v-text-field
                v-if="admin"
                v-model="home_title_left"
                label="Titel links"
                class="py-2"
              />
              <v-card-title
                v-else
                class="py-2"
              >
                {{ home_title_left }}
              </v-card-title>
              <tinyMCE
                v-if="admin"
                v-model="home_text_left"
                :init="{
                  content_css: false,
                  skin: false,
                  setup: function (ed) {
                    ed.on('init', function () {
                      this.getDoc().body.style.fontSize = '12';
                      this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                    });
                  },
                  statusbar: false,
                  height: 300,
                  menubar: false,
                  language_url: '/langs/de.js',
                  language: 'de',
                  block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                  plugins: [
                    'lists',
                    'link',
                    'paste'
                  ],
                  paste_as_text: true,
                  default_link_target: '_blank',
                  toolbar:
                    'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                }"
              />
              <v-card-text v-else>
                <span
                  :class="{
                    barrierefrei: $route.query.barrierefrei
                  }"
                  v-html="home_text_left"
                />
              </v-card-text>
            </v-sheet>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
        lg="3"
        sm="6"
        cols="12"
        class="mx-0"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            class="transparentBack"
            :elevation="hover ? 10 : 6"
            @click="clickHandler('standortsuche', $event)"
          >
            <v-img
              class="fotoHolder"
              width="100%"
              height="140"
              src="/img/startseite-standortsuche.jpg"
              position="center center"
            />
            <v-sheet
              class="pa-4 backgroundGradient"
              :class="{'sameHeight' : !admin}"
            >
              <v-text-field
                v-if="admin"
                v-model="home_title_middle"
                label="Titel Mitte links"
                class="py-2"
              />
              <v-card-title
                v-else
                class="py-2"
              >
                {{ home_title_middle }}
              </v-card-title>
              <tinyMCE
                v-if="admin"
                v-model="home_text_middle"
                :init="{
                  content_css: false,
                  skin: false,
                  setup: function (ed) {
                    ed.on('init', function () {
                      this.getDoc().body.style.fontSize = '12';
                      this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                    });
                  },
                  statusbar: false,
                  height: 300,
                  menubar: false,
                  language_url: '/langs/de.js',
                  language: 'de',
                  block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                  plugins: [
                    'lists',
                    'link',
                    'paste'
                  ],
                  paste_as_text: true,
                  default_link_target: '_blank',
                  toolbar:
                    'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                }"
              />
              <v-card-text v-else>
                <span
                  :class="{
                    barrierefrei: $route.query.barrierefrei
                  }"
                  v-html="home_text_middle"
                />
              </v-card-text>
            </v-sheet>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
        lg="3"
        sm="6"
        cols="12"
        class="mx-0"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            class="transparentBack"
            :elevation="hover ? 10 : 6"
            @click="clickHandler('businessparks', $event)"
          >
            <v-img
              class="fotoHolder"
              width="100%"
              height="140"
              src="/img/startseite-businessparks.jpeg"
              position="center center"
            />
            <v-sheet
              class="pa-4 backgroundGradient"
              :class="{'sameHeight' : !admin}"
            >
              <v-text-field
                v-if="admin"
                v-model="home_title_middle2"
                label="Titel Mitte rechts"
                class="py-2"
              />
              <v-card-title
                v-else
                class="py-2"
              >
                {{ home_title_middle2 }}
              </v-card-title>
              <tinyMCE
                v-if="admin"
                v-model="home_text_middle2"
                :init="{
                  content_css: false,
                  skin: false,
                  setup: function (ed) {
                    ed.on('init', function () {
                      this.getDoc().body.style.fontSize = '12';
                      this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                    });
                  },
                  statusbar: false,
                  height: 300,
                  menubar: false,
                  language_url: '/langs/de.js',
                  language: 'de',
                  block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                  plugins: [
                    'lists',
                    'link',
                    'paste'
                  ],
                  paste_as_text: true,
                  default_link_target: '_blank',
                  toolbar:
                    'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                }"
              />
              <v-card-text v-else>
                <span
                  :class="{
                    barrierefrei: $route.query.barrierefrei
                  }"
                  v-html="home_text_middle2"
                />
              </v-card-text>
            </v-sheet>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
        lg="3"
        sm="6"
        cols="12"
        class="mx-0"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            class="transparentBack"
            :elevation="hover ? 10 : 6"
            @click="clickHandler('technologiezentren', $event)"
          >
            <v-img
              class="fotoHolder"
              width="100%"
              height="140"
              src="/img/startseite-technologiezentren.jpeg"
              position="center center"
            />
            <v-sheet
              class="pa-4 backgroundGradient"
              :class="{'sameHeight' : !admin}"
            >
              <v-text-field
                v-if="admin"
                v-model="home_title_right"
                label="Titel rechts"
                class="py-2"
              />
              <v-card-title
                v-else
                class="py-2"
              >
                {{ home_title_right }}
              </v-card-title>
              <tinyMCE
                v-if="admin"
                v-model="home_text_right"
                :init="{
                  content_css: false,
                  skin: false,
                  setup: function (ed) {
                    ed.on('init', function () {
                      this.getDoc().body.style.fontSize = '12';
                      this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                    });
                  },
                  statusbar: false,
                  height: 300,
                  menubar: false,
                  language_url: '/langs/de.js',
                  language: 'de',
                  block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                  plugins: [
                    'lists',
                    'link',
                    'paste'
                  ],
                  paste_as_text: true,
                  default_link_target: '_blank',
                  toolbar:
                    'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                }"
              />
              <v-card-text v-else>
                <span
                  :class="{
                    barrierefrei: $route.query.barrierefrei
                  }"
                  v-html="home_text_right"
                />
              </v-card-text>
            </v-sheet>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <div style="width: 5px; height: 150px;" />
    <TheTextbausteinConfirm v-if="admin" />
  </v-container>
</template>

<script>
import TheTextbausteinConfirm from '@/components/forms/TheTextbausteinConfirm.vue';
import tinyMCE from '@tinymce/tinymce-vue';
import bindingsTextbausteine from '@/store/bindingsTextbausteine';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
  components: {
    tinyMCE,
    TheTextbausteinConfirm,
    VueSlickCarousel
  },
  props: {
    admin: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...bindingsTextbausteine(),
    carouselTextItems() {
      const doc = new DOMParser().parseFromString(this.home_header, 'text/html');
      if (doc) {
        const paragraphs = doc.querySelectorAll('p');
        if (paragraphs) {
          const paragraphsArray = Array.from(paragraphs);
          if (paragraphsArray.length) {
            return paragraphsArray.map(p => p.innerText);
          }
        }
      }
      return [];
    }
  },
  methods: {
    clickHandler(target, event) {
      if (event.srcElement.tagName === 'DIV' && !this.admin) {
        this.$router.push({path: target});
      }

    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.admin && this.$store.state.isDirty) {
      await this.$root.$confirm({
        title: 'Zurück ohne Speichern?',
        message: 'Bitte bestätigen oder verwerfen Sie Ihre Änderungen.'
      });
    } else {
      next();
    }
  }
};
</script>
<style scoped>
  .content {
    height: calc(100vh - 92px);
    overflow: auto;
  }

  .fotoHolder {
    border-radius: 5px 5px 0px 0px !important;
  }

  .carouselHolder {
    width: calc(90% - 10px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .carouselDiv {
    text-align: center;
    color: white;
    margin-top: 15px;
    display: flex !important;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  .smallCarousel {
    font-size: 22px;
    font-weight: 800;
    height: 90px;
  }

  .largeCarousel {
    font-size: 30px;
    font-weight: 800;
    height: 100px;
  }

  .xlCarousel {
    font-size: 36px;
    font-weight: 800;
    height: 120px;
  }

  .barrierefrei {
    font-size: 16px !important;
  }

  .transparentBack {
    background: none;
  }

  .backgroundGradient {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,.4) 100%);
  }

  .sameHeight {
    height: 290px;
  }

  @media only screen and (max-width: 1720px) {
  .sameHeight {
          height: 340px;
    }
  }

  @media only screen and (max-width: 1500px) {
  .sameHeight {
      height: 450px;
    }
  }

  @media only screen and (max-width: 1263px) {
  .sameHeight {
      height: 300px;
    }
  }

  @media only screen and (max-width: 850px) {
  .sameHeight {
      height: 360px;
    }
  }

  @media only screen and (max-width: 829px) {
  .sameHeight {
      height: 480px;
    }
  }

  @media only screen and (max-width: 599px) {
  .sameHeight {
      height: auto;
    }
  }
</style>
