<template>
  <v-container
    fluid
    class="content pa-0"
  >
    <v-card
      v-if="$route.query.barrierefrei"
      class="ma-10 pa-4"
      elevation="6"
      aria-label="Link zu Informationen über Businessparks der Wirtschaftsagentur Burgenland"
      href="https://wirtschaftsagentur-burgenland.at/standort/businessparks/"
      target="_blank"
    >
      <v-card-title>
        Informieren Sie sich über unsere Businessparks auf der Website der Wirtschaft Burgenland
      </v-card-title>
    </v-card>
    <div
      v-if="!$route.query.barrierefrei"
      ref="map"
      class="map"
    />
  </v-container>
</template>

<script>
import ImageLayer from 'ol/layer/Image';
import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
import View from 'ol/View';
import {Feature} from 'ol';
import {Polygon} from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Fill, Style} from 'ol/style';
import {defaults as controlDefaults} from 'ol/control';
import {defaults as interactionDefaults} from 'ol/interaction';

let map;

export default {
  watch: {
    '$route.query.barrierefrei'(value) {
      if (!value) {
        this.initMap();
      }
    }
  },
  mounted() {
    this.initMap();
  },
  methods: {
    /**
     * creates an ol-polygon from corner points of a box of the image
     */
    createPolygonFromCornerPoints(x1, y1, x2, y2) {
      const fullHeight = 2692;
      return new Polygon([[[x1, fullHeight - y1], [x1, fullHeight - y2], [x2, fullHeight - y2], [x2, fullHeight - y1], [x1, fullHeight - y1]]]);
    },

    initMap() {
      this.$nextTick(() => {
        const extent = [0, 0, 1920, 2692];
        const projection = new Projection({
          code: 'image',
          units: 'pixels',
          extent: extent
        });

        const view = new View({
          projection,
          zoom: 2,
          maxZoom: 6
        });

        const muellendorfLarge = new Feature({
          geometry: this.createPolygonFromCornerPoints(495, 116, 759, 597),
          url: 'https://www.businessparks-burgenland.at/businesspark-muellendorf/',
          type: 'large'
        });
        const muellendorfSmall = new Feature({
          geometry: this.createPolygonFromCornerPoints(492, 492, 759, 597),
          url: 'https://www.businessparks-burgenland.at/businesspark-muellendorf/',
          type: 'small'
        });

        const heiligenkreuzLarge = new Feature({
          geometry: this.createPolygonFromCornerPoints(1024, 1957, 1290, 2404),
          url: 'https://www.businessparks-burgenland.at/businesspark-heiligenkreuz/',
          type: 'large'
        });
        const heiligenkreuzSmall = new Feature({
          geometry: this.createPolygonFromCornerPoints(1024, 1957, 1290, 2062),
          url: 'https://www.businessparks-burgenland.at/businesspark-heiligenkreuz/',
          type: 'small'
        });
        const parndorfLarge = new Feature({
          geometry: this.createPolygonFromCornerPoints(1470, 1077, 1737, 1530),
          url: 'https://www.businessparks-burgenland.at/businesspark-parndorf-neusiedl-am-see/',
          type: 'large'
        });
        const parndorfSmall = new Feature({
          geometry: this.createPolygonFromCornerPoints(1470, 1077, 1737, 1181),
          url: 'https://www.businessparks-burgenland.at/businesspark-parndorf-neusiedl-am-see/',
          type: 'small'
        });
        const kittseeLarge = new Feature({
          geometry: this.createPolygonFromCornerPoints(1157, 151, 1422, 605),
          url: 'https://www.businessparks-burgenland.at/businesspark-kittsee/',
          type: 'large'
        });
        const kittseeSmall = new Feature({
          geometry: this.createPolygonFromCornerPoints(1157, 501, 1422, 605),
          url: 'https://www.businessparks-burgenland.at/businesspark-kittsee/',
          type: 'small'
        });
        const oberpullendorfLarge = new Feature({
          geometry: this.createPolygonFromCornerPoints(1153, 1334, 1422, 1691),
          url: 'https://www.businessparks-burgenland.at/',
          type: 'large'
        });
        const oberpullendorfSmall = new Feature({
          geometry: this.createPolygonFromCornerPoints(1153, 1349, 1422, 1455),
          url: 'https://www.businessparks-burgenland.at/',
          type: 'small'
        });
        const rudersdorfLarge = new Feature({
          geometry: this.createPolygonFromCornerPoints(337, 2120, 603, 2551),
          url: 'https://www.businessparks-burgenland.at/',
          type: 'large'
        });
        const rudersdorfSmall = new Feature({
          geometry: this.createPolygonFromCornerPoints(337, 2133, 603, 2238),
          url: 'https://www.businessparks-burgenland.at/',
          type: 'small'
        });

        const clickAreasLayer = new VectorLayer({
          source: new VectorSource({
            features: [muellendorfLarge, muellendorfSmall, heiligenkreuzSmall, heiligenkreuzLarge, parndorfSmall,
              parndorfLarge, kittseeSmall, kittseeLarge, rudersdorfSmall, rudersdorfLarge, oberpullendorfSmall, oberpullendorfLarge]
          }),
          style: (feature, resolution) => {
            // handle visibility differently for small and large boxes
            const type = feature.get('type');
            const zoom = view.getZoomForResolution(resolution);
            if (type === 'small' && zoom >= 2.7 || type === 'large' && zoom < 2.7) {
              return;
            } else {
              return new Style({
                fill: new Fill({
                  color: 'rgba(0, 0, 0, 0)'
                })
              });
            }
          }
        });

        map = new Map({
          layers: [
            new ImageLayer({
              source: new Static({
                url: '/img/business-parks-plan-detail-2.jpg',
                projection: projection,
                imageExtent: extent
              }),
              // dont set same zoom to prevent flicker
              minZoom: 1
            }),
            new ImageLayer({
              source: new Static({
                url: '/img/business-parks-plan-detail-1.jpg',
                projection: projection,
                imageExtent: extent
              }),
              maxZoom: 2.7
            }),
            clickAreasLayer
          ],
          //@ts-ignore
          target: this.$refs.map,
          view,
          controls: controlDefaults({
            rotate: false
          }),
          interactions: interactionDefaults({
            altShiftDragRotate: false,
            pinchRotate: false
          })
        });
        view.fit(extent, {
          padding: [50, 50, 50, 50]
        });
        map.on('click', this.mapClick);
        map.on('pointermove', this.mapHover);
      });
    },
    mapClick: function(evt) {
      const feature = map.forEachFeatureAtPixel(evt.pixel, feature => feature);
      if (feature) {
        window.open(feature.get('url'), '_blank');
      }
    },
    mapHover: function(evt) {
      map.getTargetElement().style.cursor = map.hasFeatureAtPixel(evt.pixel) ? 'pointer' : '';
    }
  }
};
</script>

<style scoped>
  .content {
    height: calc(100vh - 92px);
    overflow: auto;
    background-color: white;
  }

  .map {
    height: 100%;
    width: 100%;
  }

</style>
